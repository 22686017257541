import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Card from 'react-bootstrap/Card'

export const query = graphql`
  query($slug: String!) {
    webinarDataset(SlugURL: { eq: $slug }) {
      id
      FeaturedImageURL
      AuthorPic
      AuthorName
      AboutAuthor
      LongDescription
      ShortDescription
      Title
      SlugURL
    }
  }
`
const Webinar = ({ data }) => {

    
    

    const webinar = data.webinarDataset

    // const SlugURL = webinar.SlugURL
    // let videoUrl = '/'

    // switch(SlugURL){
    //   case "how-to-debug-c-program":
    //   videoUrl = 'https://www.youtube.com/channel/UCs3sKIFU4o4AblGJHtknHIg?sub_confirmation=1'
    //   break;

    //   case "learn-html-basics":
    //   videoUrl = 'https://www.youtube.com/channel/UCs3sKIFU4o4AblGJHtknHIg?sub_confirmation=1'
    //   break;

    //   default:
    //   videoUrl = '/'
    // }


    return (
        <Layout>
          <hr className="mt-0 mb-4 d-none d-md-block" />
            <div className="container">
              <div className="row">
                  <Card className="border-0 mb-4">
                    <div className="row">
                      <div className="col-md-6 mb-4">
                        <Card.Img variant="top" src={webinar.FeaturedImageURL} />
                      </div>
                      <div className="col-md-6 pl-4 pr-4">
                        <Card.Title style={{fontSize: "1.5rem"}}>{webinar.Title}</Card.Title>
                        <div dangerouslySetInnerHTML={{__html: webinar.LongDescription}}></div>
                        <div>
                          <div className="mb-4 mt-4"><img src={webinar.AuthorPic} style={{width: "100px",borderRadius: "50%"}} alt="trainer" /></div>
                          <p className="mb-0">Author : {webinar.AuthorName}</p>
                          <div dangerouslySetInnerHTML={{__html: webinar.AboutAuthor}} className="mt-2 font-italic"></div>
                          <a href="https://www.youtube.com/channel/UCs3sKIFU4o4AblGJHtknHIg?sub_confirmation=1" target="_blank" rel="noopener noreferrer" className="text-white btn btn-primary mt-4">View Now</a>
                        </div>
                      </div>
                    </div>
                    <Card.Body className="pl-0" style={{display:"none"}}>
                      <Card.Text>
                      <p>Are you keen on enforcing your creativity into website creation! Then this webinar “Introduction to JavaScript” will definitely be an informative one.</p>
                      <p>Give shape to your creativity with the technical skills for a fruitful career. The experts from the industry will give clarity about this field.</p>
                      <p>Fly to greater heights by giving wings to your dreams.</p>
                      </Card.Text>                     
                    </Card.Body>
                  </Card>
                  
              </div>
            </div>
        </Layout>
    )
}

export default Webinar
